<!-- 公测资产兑换页 -->
<script setup>
import { computed, onBeforeUnmount, reactive, ref } from 'vue';
import { useAppStore } from '@/store/appStore';
import { useRead } from '@/hooks/useAction';
import HaloExChangeContract from '@/contractsApi/polygon/HaloExChangeContract';
import IgoBoxExchangeContract from '@/contractsApi/polygon/IgoBoxExchangeContract';
import { tableList, setIsInitAssetsPageValue } from './useAssetsExchange';
import PackageItem from './components/PackageItem.vue';
import AssetsExchangeFuncContract from '@/contractsApi/polygon/AssetsExchangeFuncContract';
import AudltCattleContract from '@/contractsApi/polygon/AudltCattleContract';
import GalaxiesContracts from '@/contractsApi/polygon/GalaxiesContracts';
import { ASSETS_EXCHANGE_FUNC_ADDR } from '@/contracts/polygonAddress';
import ItemVoucher from '@/contractsApi/polygon/ItemVoucher';
import { useWrite } from '@/hooks/useAction';
import TipsPop from './components/TipsPop.vue';
import { BASE_OSS } from '@gConst';
import CoinToken from '@/contracts/CoinToken';
import { BVT_TOKEN_CONT } from '@/contracts/polygonAddress';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n';
const $t = i18n.global.t;

const appStore = useAppStore();

// 當前語言的banner
const banClaim = computed(() => {
  switch (appStore.curLang) {
    case 'cn':
      return `${BASE_OSS}/banner/banner-voucher-cn.png`;
    case 'kn':
      return `${BASE_OSS}/banner/banner-voucher-kn.png`;
    default:
      return `${BASE_OSS}/banner/banner-voucher.png`;
  }
});

const haloExChangeContractApi = new HaloExChangeContract();
const assetsExchangeFuncContractApi = new AssetsExchangeFuncContract();
const IgoBoxExchangeContractApi = new IgoBoxExchangeContract();
const itemVoucherApi = new ItemVoucher();
const audltCattleContractApi = new AudltCattleContract();
const bvtObj = new CoinToken({ address: BVT_TOKEN_CONT.address, abi: BVT_TOKEN_CONT.abi });
const galaxiesContractsApi = new GalaxiesContracts();

// 当前分类
const activeTab = computed(() => {
  return tableList.find((item) => item.active);
});

// 轮询timer
const checkHaloListTimer = ref(null);
// 轮询计数器
const checkHaloListCount = ref(0);
/**
 * 获取兑换券列表
 */
const [checkHaloList, { datas: haloList, loading: loadHalo }] = useRead(async () => {
  const p1 = itemVoucherApi.checkAllIteminfo();
  const p2 = haloExChangeContractApi.checkUserCardList();
  const p3 = assetsExchangeFuncContractApi.checkIGOToken();
  const p4 = audltCattleContractApi.checkUserCardList();
  const p5 = assetsExchangeFuncContractApi.checkGalaxiesToken();

  Promise.allSettled([p1, p2, p3, p4, p5]).then(() => {
    setIsInitAssetsPageValue(false);
  });
});
checkHaloList();

const hasAllowTimer = ref(null);
/**
 * 查询是否授权
 */
const [checkHasAllow, { datas: hasAllow, loading: loadHasAllow }] = useRead(async () => {
  if (activeTab.value.id === 0) {
    // 光轮宝箱兑换券
    const resp = haloExChangeContractApi.isApprovedForAll(ASSETS_EXCHANGE_FUNC_ADDR);
    const resp2 = bvtObj.allow(null, ASSETS_EXCHANGE_FUNC_ADDR);
    const res = await Promise.all([resp, resp2]);
    return res[0] && res[1];
  }

  if (activeTab.value.id === 1) {
    // 正式资产兑换券
    const resp = await itemVoucherApi.isApprovedForAll(ASSETS_EXCHANGE_FUNC_ADDR);
    return resp;
  }

  if (activeTab.value.id === 2) {
    // IGO盲盒兑换券
    const resp = IgoBoxExchangeContractApi.isApprovedForAll(ASSETS_EXCHANGE_FUNC_ADDR);
    const resp2 = bvtObj.allow(null, ASSETS_EXCHANGE_FUNC_ADDR);
    const res = await Promise.all([resp, resp2]);
    return res[0] && res[1];
  }

  if (activeTab.value.id === 3) {
    // 两者不能同时选
    if (activeTab.value.children[0].pickedCount && activeTab.value.children[1].pickedCount) return;

    if (activeTab.value.children[0].pickedCount) {
      // 选择了成年牛
      // 成年牛兑换券
      const resp = audltCattleContractApi.isApprovedForAll(ASSETS_EXCHANGE_FUNC_ADDR);
      const resp2 = bvtObj.allow(null, ASSETS_EXCHANGE_FUNC_ADDR);
      const res = await Promise.all([resp, resp2]);
      return res[0] && res[1];
    } else if (activeTab.value.children[1].pickedCount) {
      // 选择galaxy
      const resp = galaxiesContractsApi.isApprovedForAll(ASSETS_EXCHANGE_FUNC_ADDR);
      const resp2 = bvtObj.allow(null, ASSETS_EXCHANGE_FUNC_ADDR);
      const res = await Promise.all([resp, resp2]);
      return res[0] && res[1];
    } else {
      // 啥也没有
    }
  }
});
checkHasAllow();

/**
 * 选择分类
 */
function pickTab(tab) {
  tableList.forEach((item) => {
    item.active = item.id === tab.id;
  });
  checkHasAllow();
}

/**
 * 授权
 */
const [handleApprove, loadApprove] = useWrite(async () => {
  if (activeTab.value.id === 3) {
    // 啥也没有，点不动
    if (!activeTab.value.children[0].pickedCount && !activeTab.value.children[1].pickedCount) {
      ElMessage.error($t('assetsExchange.66'));
      return;
    }
  }

  clearInterval(hasAllowTimer.value);

  if (activeTab.value.id !== 1) {
    // 除了正式资产，都还要给bvt授权
    await bvtObj
      .auth(ASSETS_EXCHANGE_FUNC_ADDR)
      .then((resp) => {
        ElMessage({
          type: 'success',
          message: $t('msg.3'),
        });
      })
      .catch((err) => {
        console.log('BVT授权错误', err);
        let info = err?.['reason'] || err?.data?.message || err?.message || err;
        // 点击了拒绝信息
        if (info?.includes?.('User denied transaction')) {
          info = 'User denied transaction signature.';
        }
        // 避免信息太长看懵用户
        info = String(info).length > 100 ? 'error' : info;
        ElMessage({
          type: 'error',
          message: info,
        });
        return {
          status: false,
          datas: '0',
          message: err,
        };
      });
  }

  let status;
  // 光轮
  if (activeTab.value.id === 0) {
    status = await haloExChangeContractApi.setApprovalForAll(ASSETS_EXCHANGE_FUNC_ADDR);
  }

  // 正式资产
  else if (activeTab.value.id === 1) {
    status = await itemVoucherApi.setApprovalForAll(ASSETS_EXCHANGE_FUNC_ADDR);
  }

  // IGO盲盒
  else if (activeTab.value.id === 2) {
    status = await IgoBoxExchangeContractApi.setApprovalForAll(ASSETS_EXCHANGE_FUNC_ADDR);
  }

  // 成年牛
  else if (activeTab.value.id === 3) {
    // 两者不能同时选
    if (activeTab.value.children[0].pickedCount && activeTab.value.children[1].pickedCount) return;

    if (activeTab.value.children[0].pickedCount) {
      // 选择了成年牛
      status = await audltCattleContractApi.setApprovalForAll(ASSETS_EXCHANGE_FUNC_ADDR);
    } else if (activeTab.value.children[1].pickedCount) {
      // 选择galaxy
      status = await galaxiesContractsApi.setApprovalForAll(ASSETS_EXCHANGE_FUNC_ADDR);
    } else {
      // 啥也没选
      return;
    }
  }

  if (!status) return;

  // 授权成功之后 轮询是否授权
  hasAllowTimer.value = setInterval(() => {
    checkHasAllow();
    if (hasAllow.value) {
      // 如果发现已经是授权了的，就不继续轮了
      clearInterval(hasAllowTimer.value);
    }
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(hasAllowTimer.value);
});

/**
 * 点击一键兑换之后先显示弹窗
 */
const isShowTipsPop = ref(false);
const pickedTotalCount = ref(0); // 共选择的兑换券张数
function setShowTipsPop() {
  const tokenIds = assetsExchangeFuncContractApi.getFormatTokens(activeTab.value.id);
  console.log('选择？？', tokenIds);
  // 啥也没选
  if (!tokenIds.length) return;

  pickedTotalCount.value = tokenIds.length;

  if (activeTab.value.id === 1) {
    // 由于正式资产 获取的不是 将所有tokenId的数量罗列出来，而是一个id以及对应的数量
    const totalCount = tokenIds.reduce((acc, item) => {
      return acc + +item;
    }, 0);
    pickedTotalCount.value = totalCount;
  }

  isShowTipsPop.value = true;
}

/**
 * 处理兑换
 */
const [handleExchange, loadExchange] = useWrite(async () => {
  isShowTipsPop.value = false;
  // 清空轮询计数器
  checkHaloListCount.value = 0;
  clearInterval(checkHaloListTimer.value);

  // 光轮
  if (activeTab.value.id === 0) {
    const status = await assetsExchangeFuncContractApi.exchangeHaloBatch();
    if (!status) return;
  }

  // 正式资产
  else if (activeTab.value.id === 1) {
    const status = await assetsExchangeFuncContractApi.exchangeVoucherBatch();
    if (!status) return;
  }

  // IGO盲盒
  else if (activeTab.value.id === 2) {
    const status = await assetsExchangeFuncContractApi.exchangeIgoBatch();
    if (!status) return;
  }

  // 成年牛
  else if (activeTab.value.id === 3) {
    // 两者不能同时选
    if (activeTab.value.children[0].pickedCount && activeTab.value.children[1].pickedCount) return;

    if (activeTab.value.children[0].pickedCount) {
      // 选择了成年牛
      const status = await assetsExchangeFuncContractApi.exchangeCattleBatch();
      if (!status) return;
    } else if (activeTab.value.children[1].pickedCount) {
      // 选择了galaxies
      const status = await assetsExchangeFuncContractApi.exchangeGalaxiesBatch();
      if (!status) return;
    } else {
      return;
    }
  }

  // 兑换成功之后 轮询兑换后的最新数据
  checkHaloListTimer.value = setInterval(() => {
    checkHaloListCount.value++;
    checkHaloList();
    if (checkHaloListCount.value >= 30) {
      // 如果发现已经是授权了的，就不继续轮了
      clearInterval(checkHaloListTimer.value);
    }
  }, 1000);
});
</script>

<template>
  <div class="assets-exchange-wrap">
    <!-- banner -->
    <div class="ban-wrap">
      <img :src="banClaim" alt="" class="ban" style="object-fit: cover; height: 100%" />
    </div>

    <!-- 了解 -->
    <!-- <div class="how-airdrop" @click="setShow(true)">
      {{ $t('assetsExchange.31') }}
      <img src="@img/referral/help.png" alt="" />
    </div> -->

    <!-- 选项列表 -->
    <div class="opt-list-wrap">
      <li
        v-for="tab in tableList"
        :key="tab.id"
        :class="[{ active: tab.active }]"
        @click="pickTab(tab)"
      >
        <!-- <img :src="tab.img" alt="" class="tab-logo" /> -->
        <div class="tab-name">{{ $t(tab.text) }}</div>
      </li>
    </div>

    <!-- 内容 -->
    <div :class="['opt-container']">
      <div class="opt-content">
        <PackageItem
          v-for="item in activeTab.children"
          :key="item.id"
          :face="item.img"
          :count="item.count"
          :text="item.text"
          v-model:pickedCount="item.pickedCount"
          :type="+activeTab.id"
          :itemInfo="item"
        />
      </div>
      <button class="exchange-btn" @click="setShowTipsPop" v-loading="loadExchange" v-if="hasAllow">
        {{ $t('assetsExchange.62') }}
      </button>
      <button class="exchange-btn" @click="handleApprove" v-loading="loadApprove" v-else>
        {{ $t('common.1') }}
      </button>
    </div>
  </div>

  <!-- 弹窗 -->
  <van-popup v-model:show="isShowTipsPop">
    <TipsPop
      @cancel="isShowTipsPop = false"
      @confirm="handleExchange"
      :pickedTotalCount="pickedTotalCount"
      :type="activeTab.id"
    />
  </van-popup>
</template>

<style lang="scss" scoped>
.assets-exchange-wrap {
  background-image: url(https://game.legendranch.app/legendaryranch/slideShow/bg-home.webp);
  padding-bottom: 1rem;
  background-size: cover;
  color: #fff;
}
.ban-wrap {
  /* 利用高度决定图片宽度 */
  @include -height(3rem, mvw(500), vw(700));
}
.ban {
  width: 100%;
  height: 100%;
}

.img-slogan {
  /* width: 9.4rem; */
  @include -width(svw(400), mvw(800), vw(940));
  /* width: vw(940); */
  margin: 1.27rem auto 0;
}

.opt-list-wrap {
  @include -width(92%, 95%, 12rem);
  @include flexPos(space-between);
  margin: 0.58rem auto 0;

  @media (max-width: $pad) {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0.2rem;
    justify-content: center;
    align-items: center;
  }

  > li {
    padding: 0.06rem 0.28rem;
    border-radius: 0.26rem;
    color: #fff;
    @include flexPos(center);
    cursor: pointer;

    &.active {
      // background-color: #453366;
      border: solid 1.5px #fff;
    }

    .tab-logo {
      width: 0.5rem;
      height: 0.5rem;
    }

    .tab-name {
      font-size: 0.24rem;
      margin-left: 0.05rem;
    }
  }
}

.opt-container {
  @include -width(92%, 95%, 12rem);
  position: relative;
  margin: 0.5rem auto 0;

  border: solid 2px #45377b;
  padding: 0.42rem 0.3rem 0.66rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(58, 15, 173, 0.61) 100%);
  backdrop-filter: blur(40px);
  color: #fff;
  border-radius: 0.2rem;

  .opt-content {
    border-radius: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.42rem;

    @media (max-width: $phone) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .exchange-btn {
    width: 4.52rem;
    height: 0.82rem;
    border-radius: 1rem;
    font-size: 0.22rem;
    background-color: #8626ff;
    display: block;

    margin: 0.62rem auto 0;
  }
}

.how-airdrop {
  @include flexPos(center);
  cursor: pointer;
  font-size: 0.26rem;
  img {
    margin-left: 0.04rem;
    width: 0.22rem;
  }
}

.loading {
  color: #fff;
  text-align: center;
  font-size: 0.2rem;
}
</style>
