<script setup>
import { computed, ref } from 'vue';
import { setActiveItem, setIsShowPop } from '../useAssetsExchange';
const props = defineProps({
  // 卡片
  face: {},
  // 数量
  count: {},
  // 名字
  text: {},
  // 选择的数量
  pickedCount: {},
  // 类型：光轮宝箱、正式资产、igo盲盒、成年牛
  type: {
    type: Number,
  },
  // children信息
  itemInfo: {},
});

const emits = defineEmits(['exchange', 'update:pickedCount']);

/**
 * 修改选中值
 */
function handleChangeCount(e) {
  if (props.itemInfo.disable) {
    e.target.value = 0;
    return;
  }

  const val = e.target.value;
  // 不能小于0
  if (val < 0) {
    e.target.value = 0;
    return;
  }

  // 不能大于拥有的数量
  if (val > props.count) {
    e.target.value = props.count;
    return;
  }

  if (/\D+/.test(val) || /\./g.test(val)) {
    e.target.value = props.pickedCount;
    return;
  }
  emits('update:pickedCount', e.target.value);
}

/**
 * 增加or减少选中值
 */
function changeCount(type) {
  if (props.itemInfo.disable) return;

  if (type) {
    if (+props.pickedCount + 1 > +props.count) {
      return;
    }
    emits('update:pickedCount', +props.pickedCount + 1);
  } else {
    if (+props.pickedCount - 1 < 0) {
      return;
    }
    emits('update:pickedCount', +props.pickedCount - 1);
  }
}
</script>

<template>
  <div
    :class="[
      'package-item-wrap',
      { halo: props.type === 0 },
      { assets: props.type === 1 },
      { igoBox: props.type === 2 },
      { audlt: props.type === 3 },
    ]"
  >
    <div class="package-main">
      <div class="face-wrap">
        <img
          :src="props.face"
          alt=""
          :class="['face', { cattleVoucherBig: props.itemInfo?.cattleVoucherBig }]"
        />
        <div class="text-content">
          <div class="middle-font">{{ $t(props.text) }}</div>
          <!-- 数量 -->
          <div class="middle-font" style="margin-top: 0.06rem">
            {{ $t('assetsExchange.64') }} x{{ props.count }}
          </div>
        </div>
      </div>
    </div>

    <!-- 兑换数量 -->
    <div class="exchange-num">
      <button :class="['con-btn', { disable: props.itemInfo.disable }]" @click="changeCount(false)">
        -
      </button>
      <input type="number" class="inp-num" :value="props.pickedCount" @input="handleChangeCount" />
      <button :class="['con-btn', { disable: props.itemInfo.disable }]" @click="changeCount(true)">
        +
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.big-font {
  @include -font-size(0.32rem, mvw(32), 0.28rem);
}

.middle-font {
  @include -font-size(0.16rem, mvw(16), 0.16rem);
}

.small-font {
  @include -font-size(0.18rem, mvw(18), 0.14rem);
  margin-bottom: 0.12rem;
}

.package-item-wrap {
  /* background-color: pink; */
  background-color: #513a79be;
  @include flexPos(space-between);
  flex-direction: column;
  border-radius: 0.4rem;
  padding-bottom: 0.2rem;

  &.assets,
  &.audlt,
  &.igoBox {
    padding-top: 0.2rem;

    .text-content {
      margin: 0.12rem 0;
    }
  }

  &.halo {
    .text-content {
      margin-bottom: 0.12rem;
    }
  }
}

.package-main {
  @include -width(2.5rem, mvw(300), 3rem);
  /* @include -height(5rem, 5rem, 4.42rem); */
  padding: 0;
  background-size: 100% 100%;
  @include flexPos(space-between);
  flex-direction: column;
  text-align: center;
}

.face-wrap {
  img.face {
    width: 100%;

    &.cattleVoucherBig {
      width: 51%;
      margin: 0.3rem auto 0.2rem;
    }
  }
}

.footer {
  @include -margin-top(0.3rem, 0.3rem, 0.1rem);
}

.exchange-num {
  @include flexPos(center);
  @include -font-size(0.3rem, 0.24rem, 0.24rem);

  .inp-num {
    margin: 0 0.18rem;
    width: 0.6rem;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .con-btn {
    background-color: #fff;
    @include -width(0.4rem, 0.27rem, 0.27rem);
    @include -height(0.4rem, 0.27rem, 0.27rem);
    border-radius: 50%;
    color: #4e376f;
    @include flexPos(center);

    &.disable {
      background-color: rgb(164, 162, 162);
    }
  }
}
</style>
