import { useAppStore } from '@store/appStore';
import { ASSETS_EXCHANGE_FUNC_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { tableList, isInitAssetsPage } from '@/views/NFT/AssetsExchange/useAssetsExchange';
import { bpFormat } from '@/utils/bpMath';
import { bpWrite } from '@/service/bpAction';
import { ElMessage } from 'element-plus';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.assetsExchangeFuncObj = null;
    this.appStore = {};
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.assetsExchangeFuncObj = new ethers.Contract(
      ASSETS_EXCHANGE_FUNC_CONT.address,
      ASSETS_EXCHANGE_FUNC_CONT.abi,
      toRaw(signer)
    );
    this.defaultAccount = this.appStore.defaultAccount;
  }

  /**
   * 查询igo盲盒列表
   */
  async checkIGOToken() {
    // 创世牛兑换券
    const creation = this.assetsExchangeFuncObj.checkIGOToken(this.defaultAccount, 10004);

    // 普通小牛兑换券
    const normal = this.assetsExchangeFuncObj.checkIGOToken(this.defaultAccount, 10003);

    // 皮肤盲盒兑换券
    const skinBox = this.assetsExchangeFuncObj.checkIGOToken(this.defaultAccount, 10001);

    // 道具礼包兑换券
    const itemPack = this.assetsExchangeFuncObj.checkIGOToken(this.defaultAccount, 10002);

    const resp = await Promise.all([creation, normal, skinBox, itemPack]);

    tableList[2].children[0].count = resp[0]?.length || 0;
    tableList[2].children[1].count = resp[1]?.length || 0;
    tableList[2].children[2].count = resp[2]?.length || 0;
    tableList[2].children[3].count = resp[3]?.length || 0;

    tableList[2].children[0].allTokenIds = resp[0] || [];
    tableList[2].children[1].allTokenIds = resp[1] || [];
    tableList[2].children[2].allTokenIds = resp[2] || [];
    tableList[2].children[3].allTokenIds = resp[3] || [];

    // 首次进来，给默认选1个
    if (isInitAssetsPage.value) {
      tableList[2].children[0].pickedCount = resp[0]?.length ? 1 : 0;
      // tableList[2].children[1].pickedCount = resp[1]?.length ? 1 : 0;
      tableList[2].children[1].pickedCount = 0; // disable cattle
      tableList[2].children[2].pickedCount = resp[2]?.length ? 1 : 0;
      tableList[2].children[3].pickedCount = resp[3]?.length ? 1 : 0;
    }
  }

  /**
   * 兑换galaxies
   */
  async checkGalaxiesToken() {
    const resp = await this.assetsExchangeFuncObj.checkGalaxiesToken(this.defaultAccount);
    console.log('checkGalaxiesToken....', resp);
    tableList[3].children[1].count = resp?.length || 0;
    tableList[3].children[1].allTokenIds = resp || [];
    // tableList[3].children[1].count = 1;
    // tableList[3].children[1].allTokenIds = ['1001'];
    
    // 首次进来，给默认选1个
    if (isInitAssetsPage.value) {
      tableList[3].children[1].pickedCount = resp?.length ? 1 : 0;
      // tableList[3].children[1].pickedCount = 1;
    }
  }

  /**
   * 获取token id数组参数兑换入参
   * @param {Number} tInx tableList的索引项
   * @returns {Array} token ids
   */
  getFormatTokens(tInx) {
    if (tInx === 1) {
      // 正式資產
      const counts = tableList[1].children
        .map((table) => {
          const c = +table.pickedCount > +table.count ? table.count : table.pickedCount;
          return c;
        })
        .filter((table) => table);

      if (!counts.length) {
        ElMessage.error($t('assetsExchange.66'));
        return [];
      }

      return counts;
    }
    // 其他選項卡

    for (let i = 0, len = tableList[tInx].children.length; i < len; i++) {
      const item = tableList[tInx].children[i];
      const len = item.pickedCount > item.count ? item.count : item.pickedCount;
      tableList[tInx].children[i].pickedTokenIds = item.allTokenIds?.slice(0, len);
    }

    const tokenIds = tableList[tInx].children.map((table) => toRaw(table.pickedTokenIds));

    let result = tokenIds.flat(Infinity);
    result = result.map((item) => +item);

    if (!result.length) {
      ElMessage.error($t('assetsExchange.66'));
      return [];
    }

    return result;
  }

  /**
   * 兑换光轮宝箱
   */
  async exchangeHaloBatch() {
    const result = this.getFormatTokens(0);
    if (!result.length) return;

    const { status } = await bpWrite(false, this.assetsExchangeFuncObj.exchangeHaloBatch, result);
    return status;
  }

  /**
   * 兑换igo
   */
  async exchangeIgoBatch() {
    const result = this.getFormatTokens(2);
    if (!result.length) return;

    const { status } = await bpWrite(false, this.assetsExchangeFuncObj.exchangeIgoBatch, result);
    return status;
  }

  /**
   * 兑换成年牛
   */
  async exchangeCattleBatch() {
    console.log('兑换成年牛...');
    const result = this.getFormatTokens(3);
    if (!result.length) return;

    const { status } = await bpWrite(false, this.assetsExchangeFuncObj.exchangeCattleBatch, result);
    return status;
  }

  /**
   * 兑换galaxies
   * @returns
   */
  async exchangeGalaxiesBatch() {
    console.log('兑换galaxies...');
    const result = this.getFormatTokens(3);
    if (!result.length) return;

    const { status } = await bpWrite(
      false,
      this.assetsExchangeFuncObj.exchangeGalaxiesBatch,
      result
    );
    return status;
  }

  /**
   * 兑换正式资产
   */
  async exchangeVoucherBatch() {
    const ids = tableList[1].children.map((table) => table.id);
    const counts = tableList[1].children.map((table) => {
      const c = +table.pickedCount > +table.count ? table.count : table.pickedCount;
      return c;
    });
    const { status } = await bpWrite(
      false,
      this.assetsExchangeFuncObj.exchangeVoucherBatch,
      ids,
      counts
    );
    return status;
  }
}
