<!-- 轉賬{{$t('dialog.6')}}弹窗 -->
<script setup>
import { defineProps, inject, watchEffect, ref } from 'vue';
import { useAppStore } from '@store/appStore';
const appStore = useAppStore();

const props = defineProps({
  // 一共选择的兑换券张数
  pickedTotalCount: {
    default: 0,
    type: Number,
  },

  // 选项卡类型
  type: {
    type: Number,
  },
});
const emits = defineEmits(['cancel', 'confirm']);

/**
 * 处理购买数量
 * @param {String} isAdd true: 加，false: 减
 */
const transferNum = ref(1);
function handleNum(isAdd) {
  if (isAdd && transferNum.value < 20) {
    transferNum.value += 1;
  }

  if (!isAdd && transferNum.value > 1) {
    transferNum.value -= 1;
  }
}
</script>

<template>
  <div class="transfer-pop-wrap">
    <!-- <header>{{ $t('detail.23') }}</header> -->
    <div class="tips" v-if="props.type === 1">
      {{ $t('assetsExchange.65', [props.pickedTotalCount, 0]) }}
    </div>
    <div class="tips" v-else>
      {{ $t('assetsExchange.65', [props.pickedTotalCount, +props.pickedTotalCount * 10]) }}
    </div>
    <!-- 按钮 -->
    <div class="btn-wrap">
      <button @click="emits('confirm')">{{ $t('dialog.1') }}</button>
      <button @click="emits('cancel')">
        {{ $t('dialog.6') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transfer-pop-wrap {
  width: 9.38rem;
  @include -width(5rem, mvw(900), 9.38rem);
  background-image: linear-gradient(360deg, #402866 0%, #000 100%);
  padding: 0.24rem 0.4rem 0.5rem;
  border: solid 0.01rem rgba(255, 255, 255, 0.3);
  border-radius: 0.1rem;
  color: #fff;
}

header {
  font-size: 0.4rem;
  margin-bottom: 0.08rem;
  @include -media($phone, font-size, 0.6rem);
  @include -media($phone, margin-bottom, 0.8rem);
}
.block-wrap {
  display: flex;
  flex-direction: column;
  @include -font-size(0.24rem, 0.24rem, 0.24rem);
  .label {
    font-size: 0.24rem;
    margin-bottom: 0.25rem;
  }

  .num {
    font-size: 0.48rem;
    font-weight: bold;
    color: #fffc2a;
    text-align: flex-end;
  }

  .btn-warp {
    @include flexPos(flex-start, center);
    flex-direction: row;
    background-color: transparent;

    .btn {
      width: 0.4rem;
      height: 0.4rem;
      @include flexPos(center);
      border: 1px solid #fff;
    }

    &.sub {
      border-radius: 3px 0px 0px 3px;
      border-right: none;
    }

    &.plus {
      border-radius: 0 3px 3px 0;
      border-left: none;
    }
    .buy-num {
      width: 1.2rem;
      text-align: center;
      background: transparent;
      border: none;
    }
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tips {
  font-size: 0.24rem;
  margin-top: 0.46rem;
  @include -media($phone, font-size, 0.28rem);
  @include -media($phone, margin-bottom, 0.28rem);
  text-align: center;
}

.inp {
  width: 100%;
  height: 0.6rem;
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: 0.22rem;
  padding-left: 0.12rem;
  font-size: 0.22rem;
}

.btn-wrap {
  margin: 0.82rem auto 0.06rem;
  width: 100%;
  @include flexPos(space-around, center);
  button {
    width: 1.57rem;
    height: 0.49rem;
    border-radius: 0.25rem;
    font-size: 0.22rem;
    font-weight: 500;
    background-color: #5b3bb7;
    // border: solid 1px #fff;
    @include -media($phone, font-size, 0.28rem);
    cursor: pointer;
  }
}
</style>
