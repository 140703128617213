import { useAppStore } from '@store/appStore';
import { GALAXIES_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { tableList, isInitAssetsPage } from '@/views/NFT/AssetsExchange/useAssetsExchange';
import { bpFormat } from '@/utils/bpMath';
import { bpWrite, bpRead } from '@/service/bpAction';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.galaxiesObj = null;
    this.appStore = {};
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.galaxiesObj = new ethers.Contract(GALAXIES_CONT.address, GALAXIES_CONT.abi, toRaw(signer));
    this.defaultAccount = this.appStore.defaultAccount;
  }
  /**
   * 是否授权
   * @param addr 动作合约
   */
  async isApprovedForAll(addr) {
    console.log('galaxies查看是否授权');

    const { status, datas } = await bpRead(
      this.galaxiesObj.isApprovedForAll,
      this.defaultAccount,
      addr
    );
    if (!status) console.log('isApprovedForAll...error...');
    return datas;
  }

  /**
   * 授权
   */
  async setApprovalForAll(addr) {
    console.log('galaxies授权');

    const { status } = await bpWrite($t('msg.3'), this.galaxiesObj.setApprovalForAll, addr, true);
    return status;
  }
}
