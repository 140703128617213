import { useAppStore } from '@store/appStore';
import { IGO_BOX_EXCHANGE_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { tableList } from '@/views/NFT/AssetsExchange/useAssetsExchange';
import { bpFormat } from '@/utils/bpMath';
import { bpWrite, bpRead } from '@/service/bpAction';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.igoBoxExchangeObj = null;
    this.appStore = {};
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.igoBoxExchangeObj = new ethers.Contract(
      IGO_BOX_EXCHANGE_CONT.address,
      IGO_BOX_EXCHANGE_CONT.abi,
      toRaw(signer)
    );
    this.defaultAccount = this.appStore.defaultAccount;
  }

  /**
   * 是否授权
   * @param addr 动作合约
   */
  async isApprovedForAll(addr) {
    const { status, datas } = await bpRead(
      this.igoBoxExchangeObj.isApprovedForAll,
      this.defaultAccount,
      addr
    );
    if (!status) console.log('isApprovedForAll...error...');
    return datas;
  }

  /**
   * 授权
   */
  async setApprovalForAll(addr) {
    const { status } = await bpWrite(
      $t('msg.3'),
      this.igoBoxExchangeObj.setApprovalForAll,
      addr,
      true
    );
    return status;
  }

  /**
   * 查询igo盲盒兑换列表
   */
  async batchTokenURI() {
    // const resp = await this.igoBoxExchangeObj.batchTokenURI(this.defaultAccount);
    // console.log('resp...', resp);
    /* const tokenIds = resp[1].map((item) => {
      return item.slice(-5);
    }); */

    // tableList[2].children
    // console.log('tokenIds....', tokenIds);
  }
}
