import { useAppStore } from '@store/appStore';
import { AUDLT_CATTLE_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { tableList } from '@/views/NFT/AssetsExchange/useAssetsExchange';
import { bpFormat } from '@/utils/bpMath';
import { bpWrite, bpRead } from '@/service/bpAction';
import { isInitAssetsPage } from '@/views/NFT/AssetsExchange/useAssetsExchange';
// 成年牛合约
const $t = i18n.global.t;

export default class {
  constructor() {
    this.audltCattleObj = null;
    this.appStore = {};
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.audltCattleObj = new ethers.Contract(
      AUDLT_CATTLE_CONT.address,
      AUDLT_CATTLE_CONT.abi,
      toRaw(signer)
    );
    this.defaultAccount = this.appStore.defaultAccount;
  }
  /**
   * 是否授权
   * @param addr 动作合约
   */
  async isApprovedForAll(addr) {
    console.log('成年牛。。查看是否授权');

    const { status, datas } = await bpRead(
      this.audltCattleObj.isApprovedForAll,
      this.defaultAccount,
      addr
    );
    if (!status) console.log('isApprovedForAll...error...');
    return datas;
  }

  /**
   * 授权
   */
  async setApprovalForAll(addr) {
    console.log('成年牛。。授权');

    const { status } = await bpWrite(
      $t('msg.3'),
      this.audltCattleObj.setApprovalForAll,
      addr,
      true
    );
    return status;
  }

  /**
   * 查询成年牛卡片
   */
  async checkUserCardList() {
    const { status, datas } = await bpRead(
      this.audltCattleObj.checkUserCardList,
      this.defaultAccount
    );
    if (!status) return;

    const tokenIds = datas.map((item) => +item);

    tableList[3].children[0].count = datas?.length || 0;
    tableList[3].children[0].allTokenIds = tokenIds;
    // 首次进来，给默认选1个
    if (isInitAssetsPage.value) {
      tableList[3].children[0].pickedCount = datas?.length ? 1 : 0;
    }
  }
}
