import { useAppStore } from '@store/appStore';
import { HALO_BLIND_BOX_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { tableList, isInitAssetsPage } from '@/views/NFT/AssetsExchange/useAssetsExchange';
import { bpFormat } from '@/utils/bpMath';
import { bpWrite, bpRead } from '@/service/bpAction';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.haloExchangeObj = null;
    this.appStore = {};
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.haloExchangeObj = new ethers.Contract(
      HALO_BLIND_BOX_CONT.address,
      HALO_BLIND_BOX_CONT.abi,
      toRaw(signer)
    );
    this.defaultAccount = this.appStore.defaultAccount;
  }
  /**
   * 是否授权
   * @param addr 动作合约
   */
  async isApprovedForAll(addr) {
    const { status, datas } = await bpRead(
      this.haloExchangeObj.isApprovedForAll,
      this.defaultAccount,
      addr
    );
    if (!status) console.log('isApprovedForAll...error...');
    return datas;
  }

  /**
   * 授权
   */
  async setApprovalForAll(addr) {
    const { status } = await bpWrite(
      $t('msg.3'),
      this.haloExchangeObj.setApprovalForAll,
      addr,
      true
    );
    return status;
  }

  /**
   * 查询用户光轮宝箱内的卡片列表
   */
  async checkUserCardList() {
    // 获取创世牛兑换券列表
    const creationRes = this.haloExchangeObj.checkUserCardList(this.defaultAccount, 2);
    // 获取普通小牛兑换券
    const normalRes = this.haloExchangeObj.checkUserCardList(this.defaultAccount, 3);
    // 获取家园星球兑换券
    const homeRes = this.haloExchangeObj.checkUserCardList(this.defaultAccount, 5);

    const resp = await Promise.all([creationRes, normalRes, homeRes]);
    console.log('获取结果', resp);

    const result = resp.map((item) => {
      return item.map((it) => {
        return String(it);
      });
    });

    console.log('记过222', result);

    tableList[0].children[0].count = result[0]?.length || 0;
    tableList[0].children[1].count = result[1]?.length || 0;
    tableList[0].children[2].count = result[2]?.length || 0;

    tableList[0].children[0].allTokenIds = result[0] || [];
    tableList[0].children[1].allTokenIds = result[1] || [];
    tableList[0].children[2].allTokenIds = result[2] || [];

    // 如果首次进来，给默认选1个
    if (isInitAssetsPage.value) {
      tableList[0].children[0].pickedCount = result[0]?.length ? 1 : 0;
      // tableList[0].children[1].pickedCount = result[1]?.length ? 1 : 0;
      tableList[0].children[1].pickedCount = 0; // disable cattle
      tableList[0].children[2].pickedCount = result[2]?.length ? 1 : 0;
    }
  }
}
